@import url('https://fonts.googleapis.com/css?family=Roboto:300,300i,400');

html{
    height: 100%;
}

body {
    background: #fff;
    font-family: 'Rubik', sans-serif;
    margin: 0;
    overflow-x: hidden;
    color: #313131;
    font-weight: 300;
    position: relative;
    height: 100%;
}
input {
    font-size: 16px;
}
.chat-list {
    list-style: none;
    padding: 0px 0px;
    margin: 0px;
}

.chat-list li {
    margin-bottom: 24px;
    overflow: hidden;
}

.chat-list .chat-image {
    display: inline-block;
    float: left;
    text-align: center;
    width: 50px;
    margin: 10px 0 0 10px;
}

.chat-list .chat-image img {
    border-radius: 100%;
    width: 100%;
}

.quick_reply img{
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
    max-width: 250px;
}

.chat-list .chat-body {
    display: inline-block;
    float: left;
    font-size: 12px;
    margin: 0 0 0 20px;
    width: 65%;
    position: relative;
    z-index: 0;
}

.chat-list .chat-body  {margin-top:10px}


.chat-list li.even .chat-body:not(.loading-bar)::before {

    position: absolute;
    top: 10px;
    right: 0;
    width: 0px;
    height: 0;
    content: '';
    border: 5px solid transparent;
    border-right-color: #dfe9ef;
    border-left-color: transparent;
    z-index: 999;
    left: -10px;

}

.chat-list .chat-text {
    background: #dfe9ef;
    border-radius: 0px 8px 8px 8px;
    display: inline-block;
    padding: 15px;
    font-size: 14px;
    position: relative;
    color:#000;
}

.chat-list .chat-text p {
    margin: 0px;
    padding-top: 3px;
}

.chat-list .chat-text b {
    color: #a3afb7;
    font-size: 10px;
    opacity: 1;
    font-weight: normal;
}

.chat-list .odd .chat-image {
    float: right!important;
    margin: 0 10px 0 0;
}

.chat-list .odd .chat-body {
    float: right!important;
    margin-right: 12px;
    text-align: right;
}

.chat-list .odd .chat-text {
    background: #4f81bd;
    border-radius: 8px 0px 8px 8px;
    color: white;
}

.p-t-30 {
    padding-top: 30px!important;
}

.chat-widget-details-box { height: 100%; position: relative; }
.chat-widget-details-box .chat-box{ height: 100%; }
.chat-widget-details-box .chat-box .chat-list{ height: calc(100% - 56px); overflow-y: scroll; }
.chat-widget-details-box .chat-box .no-height{ height: auto !important; overflow-y: auto !important; }

.chat-widget-details-box .chat-box .send-chat-box{  }

.chat-widget-details-box .chat-box .send-chat-box {
    padding: 10px 0;
    margin: 0px;
    overflow: hidden;
    position: relative;
    bottom: initial;
    width: 100%;
}

.chat-widget-details-box .chat-box .send-chat-box .form-control {
    width: calc(100% - 135px);
    height: 35px;
    padding: 6px 15px;
    font-size: 16px;
    line-height: 1.57142857;
    color: #3f4c53;
    /*background-color: #f3f7f9;*/
    background-image: none;
    border: 1px solid #e4eaec;
    border-radius: 3px;
    margin-left:12px;
    
}

.form-control:not(select) { -webkit-appearance: none; }

.form-control:disabled {
    cursor: not-allowed;
}

.btn-primary:disabled {
    cursor: not-allowed;
}

.chat-widget-details-box .chat-box .send-chat-box .custom-send {
    position: absolute;
    right: 15px;
    bottom: initial;
    top:11px;
}

.chat-widget-details-box .chat-box .send-chat-box .custom-send button {
    background-color: #4f81bd !important;
    border-color: #4f81bd !important;
    opacity: 1;
    transition: opacity .2s linear;
    border: 1px solid #707cd2;
    color: #fff !important;
}

.btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
}

.chat-widget-details-box .chat-box .chat-widget-header{
    overflow: hidden;
}


.chat-widget-details-box .chat-box .chat-widget-header .close-icon-chat a{
    color: #313131 !important;
}


.chat-list .chat-body .loading-bar {
    display: inline-block;
    float: left;
    
    margin: 0 0 0 20px;
    width: 65%;
}

.chat-list .loading-bar .chat-text {
    background-image: url("https://chat.planetnorm.com/images/typing-chat.png");
    display: inline-block;
    padding: 0;
    font-size: 14px;
    position: relative;
    background-color: transparent;
    background-size: 100%;
    height: 42px;
    width: 70px;
}



.chat-list .loading-bar .chat-text p {
    margin: 0px;
    padding: 0px;
    position: absolute;
    left: 18px;
    top: 21px;
}

.chat-list .chat-body.loading-bar::before{
    content: initial;
}

/* for date visibility */
.react-datepicker-popper{
    position: fixed !important;
    will-change: transform;
    top: initial !important; 
    right: 24px !important;
    left: initial!important;
    bottom: 80px !important;
    transform: translate3d(0px, 46px, 0px) !important;
}
.react-datepicker-wrapper{
    width: 100% !important;
}
