@import url('https://fonts.googleapis.com/css?family=Muli:300,400,600,700&display=swap');

.chat-box-optional .chat-list .chat-body::before {
    display: none;
}

html{
    height: 100%;
}

body {
    background: #fff;
    font-family: 'Rubik', sans-serif;
    margin: 0;
    overflow-x: hidden;
    color: #313131;
    font-weight: 300;
    position: relative;
    height: 100%;
}
input {
    font-size: 16px;
}
.chat-list {
    list-style: none;
    padding: 0px 0px;
    margin: 0px;
}

.chat-list li {
    margin-bottom: 24px;
    overflow: hidden;
}

.chat-list .chat-image {
    display: inline-block;
    float: left;
    text-align: center;
    width: 50px;
    margin: 10px 0 0 10px;
}

.chat-list .chat-image img {
    border-radius: 100%;
    width: 100%;
}

.quick_reply img{
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
    max-width: 250px;
}

.chat-list .chat-body {
    display: inline-block;
    float: left;
    font-size: 12px;
    margin: 0 0 0 20px;
    width: 65%;
    position: relative;
    z-index: 0;
}

.chat-box-optional .chat-list li.even .chat-body .date-div{
    /* overflow: hidden; */
    clear: both;
    display: inline-flex;
    flex-direction: column;
}

.chat-box-optional .chat-list li.even .chat-body .date-div:last-child span{
    clear: both;
    text-align: right;
    color: #A6A6A6;
    font-size:13px;
}

.chat-box-optional .chat-list li.odd .chat-body div:last-child{
    overflow: visible;
    clear: both;
    display: inline-flex;
    flex-direction: column;
}

.chat-box-optional .chat-list li.odd .chat-body div:last-child b{
    clear: both;
    text-align: left;
}

.chat-list .chat-body  {margin-top:10px}


/* .chat-box-optional .chat-list li.even .chat-body div:last-child .chat-text:after {
    position: absolute;
    right: 0px;
    width: 0px;
    height: 0;
    content: '';
    border: 7px solid transparent;
    border-left-color: transparent;
    z-index: 999;
    bottom: -13px;
    left: 20px;
    border-top-color: inherit !important;
} */
.chat-box-optional .chat-list li.even .chat-body div:last-child .chat-text:after {
    position: absolute;
    right: 0px;
    width: 0px;
    height: 0;
    content: '';
    border: 7px solid transparent;
    border-left-color: transparent;
    z-index: 999;
    top: 14px;
    left: -14px;
    border-top-color: inherit !important;
    -webkit-transform: rotate(180deg);
    transform: rotate(90deg);
}



.chat-box-optional .chat-list .chat-text {
    background: #dde3eb;
    border-radius: 0px 8px 8px 8px;
    display: inline-block;
    padding: 15px;
    position: relative;
    color:#787878;
    font-size:16px;
}

.chat-box-optional .chat-list .chat-text p {
    margin: 0px;
    padding-top: 3px;
}

.chat-box-optional .chat-list .chat-text b {
    color: #a3afb7;
    font-size: 10px;
    opacity: 1;
    font-weight: normal;
}

.chat-list .odd .chat-image {
    float: right!important;
    margin: 0 10px 0 0;
}

.chat-list .odd .chat-body {
    float: right!important;
    margin-right: 12px;
    text-align: right;
}

.chat-box-optional .chat-list .odd .chat-text {
    background: #eef4e3;
    border-radius: 8px 0px 8px 8px;
    color: #787878;
}

.p-t-30 {
    padding-top: 30px!important;
}

.chat-widget-details-box { height: 100%; position: relative; }
.chat-widget-details-box .chat-box{ height: 100%; }
.chat-widget-details-box .chat-box .chat-list{ height: calc(100% - 56px); overflow-y: scroll; }
.chat-widget-details-box .chat-box .no-height{ height: auto !important; overflow-y: auto !important; }
.chat-widget-details-box .chat-box .send-chat-box{  }

.chat-widget-details-box .chat-box .send-chat-box {
    padding: 10px 0;
    margin: 0px;
    overflow: hidden;
    position: relative;
    bottom: initial;
    width: 100%;
}

.chat-widget-details-box .chat-box .send-chat-box .form-control {
    width: calc(100% - 135px);
    height: 35px;
    padding: 6px 15px;
    font-size: 16px;
    line-height: 1.57142857;
    color: #A6A6A6;
    /*background-color: #f3f7f9;*/
    background-image: none;
    border: 1px solid #e4eaec;
    border-radius: 20px;
    margin-left:12px;
    outline: none;
}

.chat-box-optional .send-chat-box .field-with-icon {
    height: 34px;
    padding: 0px 15px;
    font-size: 16px;
    line-height: 1.57142857;
    color: #a6a6a6;
    background-image: none;
    border: 1px solid #e4eaec;
    border-radius: 20px;
    margin: 0 15px;
    display: -webkit-flex;
    display: flex;
}

.chat-box-optional .send-chat-box .field-with-icon .formField {
    width: 100%;
    border: none;
    outline: none;
    line-height: 36px;
    height: 34px;
    padding: 0;
    font-size: 16px;
    background-color: transparent !important;
}

.chat-box-optional .send-chat-box .field-with-icon .sendBtn {
    margin-left: 15px;
    padding-top: 7px;
}

.form-control:not(select) { -webkit-appearance: none; }

.form-control:disabled {
    cursor: not-allowed;
}

.btn-primary:disabled {
    cursor: not-allowed;
}

.chat-widget-details-box .chat-box .send-chat-box .custom-send {
    position: absolute;
    right: 15px;
    bottom: initial;
    top:11px;
}

.chat-widget-details-box .chat-box-optional .send-chat-box .custom-send button {
    background-color: #dde3eb  !important;
    border-color: #dde3eb  !important;
    opacity: 1;
    transition: opacity .2s linear;
    border: 1px solid #707cd2;
    color: #fff !important;
}

.btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
}

.chat-widget-details-box .chat-box-optional .chat-widget-header{
    overflow: hidden;
}


.chat-widget-details-box .chat-box-optional .chat-widget-header .close-icon-chat a{
    color: #313131 !important;
}


.chat-list .chat-body .loading-bar {
    display: inline-block;
    float: left;
    
    margin: 0 0 0 20px;
    width: 65%;
}

.chat-box-optional .chat-list .loading-bar .chat-text {
    background-image: none !important;
    display: inline-block;
    padding: 0;
    font-size: 14px;
    position: relative;
    background-color: transparent;
    background-size: 100%;
    height: 39px;
    width: 70px;
}
.chat-box-optional .chat-list .loading-bar .chat-text:after {
    position: absolute;
    right: 0px;
    width: 0px;
    height: 0;
    content: '';
    border: 7px solid transparent;
    border-left-color: transparent;
    z-index: 999;
    top: 14px;
    left: -14px;
    border-top-color: inherit !important;
    -webkit-transform: rotate(180deg);
    transform: rotate(90deg);
}

.chat-box-optional .chat-list .loading-wait:after {
    position: absolute;
    right: 0px;
    width: 0px;
    height: 0;
    content: '';
    border: 7px solid transparent;
    border-left-color: transparent;
    z-index: 999;
    top: 14px;
    left: -14px;
    border-top-color: inherit !important;
    -webkit-transform: rotate(180deg);
    transform: rotate(90deg);
}

.chat-box-optional .chat-list .loading-bar .chat-text p {
    margin: 0px;
    padding: 0px;
    position: absolute;
    left: 15px;
    top: 15px;
}

.chat-box-optional .chat-list .chat-body.loading-bar::before{
    content: initial;
}




